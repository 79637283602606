import { SpaceSize } from '@src/lib/styles/space';
import { FontSizes } from '@src/lib/styles/theme';

import ImageModal from './ImageModal';
import { GridContainer, Heading, StyledChip } from './style';

export interface RenderCardValue {
  id: string;
  name: string;
  isDisable?: boolean;
  secondName?: string;
  line2?: string;
  size?: FontSizes;
}

export const RenderChipComponent = ({
  heading,
  keyName,
  floors,
  values,
  columnNumber = 3,
  handleSelect,
  selectedValues,
  floorNotFound,
  isSelctedValue,
  marginBottom,
}: {
  values?: RenderCardValue[];
  keyName: string;
  floors?: { floorNumber: number; floorMap?: string; floorMapThumbnail?: string }[];
  heading: string;
  handleSelect: (key: string, value: string) => void;
  columnNumber?: number;
  floorNotFound?: boolean;
  isSelctedValue?: string;
  marginBottom?: SpaceSize;
  selectedValues?: any;
}) => {
  const LOCATION_KEYS = ['location', 'datePref', 'startTimeTemp', 'startTime', 'endTimeTemp', 'endTime', 'capacity'];

  const isLocationKey = LOCATION_KEYS.includes(keyName);

  const getSize = () => {
    if (keyName === 'capacity') return 's';

    if (['startTimeTemp', 'startTime', 'endTimeTemp', 'endTime'].includes(keyName)) return 'm';

    return undefined;
  };

  const handleChipClick = (id: string) => handleSelect(keyName, id);

  const renderChip = ({ id, name, isDisable, secondName, line2, size }: RenderCardValue) => {
    const isSelected = selectedValues?.[keyName] === id || id === isSelctedValue;

    return (
      <StyledChip
        key={id}
        onClick={() => handleChipClick(id)}
        $isDisable={isDisable}
        $isSelected={isSelected}
        $isLocation={isLocationKey}
        $size={getSize() || size}
        style={{
          flexDirection: line2 ? 'column' : 'row',
          flexWrap: 'wrap',
          ...(isLocationKey ? { padding: 0 } : {}),
        }}
      >
        {name}
        {secondName && <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{secondName}</span>}
        {line2 && <div>{line2}</div>}
      </StyledChip>
    );
  };

  const renderFloor = ({
    floorNumber,
    floorMap,
    floorMapThumbnail,
  }: {
    floorNumber: number;
    floorMap?: string;
    floorMapThumbnail?: string;
  }) => {
    const isSelected = selectedValues?.[keyName] === floorNumber.toString();

    return (
      <div key={`${floorNumber}-floors`}>
        <ImageModal
          imageUrl={floorMap || ''}
          thumbnailUrl={floorNumber === 0 ? '/images/1000289544.png' : floorMapThumbnail}
          text={floorNumber === 0 ? 'Any Floor' : `${floorNumber} Floor`}
          handleTextClick={() => handleSelect(keyName, floorNumber.toString())}
          isSelected={isSelected}
        />
      </div>
    );
  };

  return (
    <GridContainer $columnNumber={columnNumber} $marginBottom={marginBottom}>
      <Heading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          userSelect: 'none',
          textTransform: 'uppercase',
        }}
      >
        {heading}
      </Heading>

      {values?.map(renderChip)}

      {floors?.map(renderFloor)}

      {floorNotFound && keyName === 'floorNumber' && (
        <StyledChip style={{ gridColumn: '1/-1' }}>No Floor Found</StyledChip>
      )}
    </GridContainer>
  );
};
