import { DateRange } from 'react-date-range';
import styled from 'styled-components';

import { Breakpoints } from '@src/lib/styles/breakpoints';
import { SpaceSize } from '@src/lib/styles/space';
import { FontSizes } from '@src/lib/styles/theme';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const GridContainer = styled.div<{ $columnNumber: number; $marginBottom?: SpaceSize }>`
  display: grid;
  grid-template-columns: repeat(${({ $columnNumber }) => $columnNumber}, 1fr);
  gap: 8px;
  margin-bottom: ${({ theme, $marginBottom }) => theme.space.get($marginBottom || 'xl')};
  padding-bottom: 10px;
`;

export const Heading = styled.div<{ $size?: FontSizes }>`
  grid-column: 1 / -1;
  padding: 0;
  margin: 0;
  font-size: ${({ theme, $size }) => theme.fontSize.get($size || 'l')};
  font-weight: 800;
  text-transform: capitalize;
`;

export const StyledChip = styled.div<{
  $isSelected?: boolean;
  $fullHeight?: boolean;
  $isLocation?: boolean;
  $size?: FontSizes;
  $isDisable?: boolean;
  $isPadding?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  padding: ${({ theme }) => theme.space.get('xs')};
  ${({ theme, $isPadding }) => ($isPadding ? { paddingTop: `calc(${theme.space.get('xs')} + 1px)` } : {})};
  border: 2px solid #05b6d8;
  color: #05b6d8;
  font-size: ${({ theme, $size }) => theme.fontSize.get($size || 's')};
  border-radius: 5px;
  box-sizing: border-box;
  white-space: wrap;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  ${({ $fullHeight }) => ($fullHeight ? 'height: auto;' : '')}
  ${({ $isLocation }) => ($isLocation ? 'height: 50px;' : '')}
  ${({ $isSelected }) =>
    $isSelected
      ? `
  background: #05b6d8;
  color: white;
  `
      : ''}
  ${Breakpoints.MOBILE} {
    padding: ${({ theme }) => theme.space.get('2xs')};
    ${({ theme, $isPadding }) => ($isPadding ? { paddingTop: `calc(${theme.space.get('2xs')} - 1px)` } : {})};
  }

  ${({ $isDisable }) => ($isDisable ? 'background: darkgray; color: white; border: 2px solid darkgray;' : '')}
`;

export const DownloadButton = styled.button<{ $isSelected?: boolean }>`
  background-color: ${({ $isSelected }) => ($isSelected ? 'white' : '#05b6d8')};
  color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  border: none;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ $isSelected }) => ($isSelected ? '#e0f7fa' : '#028c9e')};
    color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  }

  &:active {
    background-color: ${({ $isSelected }) => ($isSelected ? '#b2ebf2' : '#016a74')};
    color: ${({ $isSelected }) => ($isSelected ? '#05b6d8' : 'white')};
  }
`;

export const StyledHeading = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.get('2xs')};
  margin-bottom: ${({ theme }) => theme.space.get('2xs')};
  margin-top: ${({ theme }) => theme.space.get('2xs')};
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space.get('s')};
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
`;
export const StyledMainBox = styled.div(() => ({
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
  marginTop: '60px',
}));

export const StyledSelectedGrid = styled.div<{ $isConference?: boolean; $isNonMargin?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  ${({ $isConference }) =>
    $isConference
      ? ''
      : `
    
    grid-template-rows: repeat(2, 1fr);
    `}
  ${({ $isNonMargin, theme }) =>
    $isNonMargin
      ? ''
      : `
      
        margin-bottom: ${theme.space.get('xl')};
      `}
`;

export const StyledDateRange = styled(DateRange)<{ $isDesktop: boolean }>(({ $isDesktop }) => ({
  width: '100%',
  position: 'relative',
  // marginBottom: '70px',
  '.rdrDateDisplayWrapper': {
    display: 'none',
  },
  '.rdrMonthAndYearWrapper': {
    '.rdrMonthAndYearPickers': {
      select: {
        fontWeight: '700',
        fontSize: $isDesktop ? '18px' : '15px',
      },
    },
  },
  '.rdrMonths': {
    width: '100%',
    '.rdrMonth': {
      padding: '0',
      paddingBottom: '24px',
      width: '100%',
      '.rdrWeekDays': {
        '.rdrWeekDay': {
          fontWeight: '700',
          fontSize: $isDesktop ? '18px' : '15px',
        },
      },
      '.rdrDays': {
        '.rdrDay': {
          '.rdrDayNumber': {
            span: {
              fontWeight: '600',
              fontSize: $isDesktop ? '17px' : '14px',
            },
          },
        },
      },
    },
  },
}));

export const DateContainer = styled.div`
  background: rgb(239, 242, 247);
  display: flex;
  gap: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const DateBox = styled.div<{ $isDesktop: boolean }>`
  text-align: center;
  border: 2px solid blue;
  font-weight: 700;
  flex: 1;
  padding: 10px;
  font-size: ${({ $isDesktop }) => ($isDesktop ? '20px' : '16px')};
  background: white;
`;

export const dateCalendarStyled = (isDesktop: boolean) => ({
  '&.MuiDateCalendar-root': {
    margin: '0',
    height: 'auto',
    maxHeight: 'none',
    width: '100%',
    '.MuiDayCalendar-root': {
      '.MuiPickersSlideTransition-root': {
        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiPickersDay-today': {
              background: 'blue',
              color: 'white',
            },
          },
        },
      },
      border: '3px solid purple',
      borderRadius: '0 0 8px 8px',
      marginBottom: '20px',
      '.MuiDayCalendar-slideTransition': {
        minHeight: '214px',
      },
      '.MuiDayCalendar-weekDayLabel': {
        flex: 1,
        fontWeight: '700',
        fontSize: isDesktop ? '18px' : '15px',
      },
      '.MuiPickersDay-root': {
        flex: 1,
        fontSize: isDesktop ? '18px' : '15px',
        borderRadius: '0px',
        height: '40px',
        fontWeight: '700',
      },
    },
    '.MuiPickersCalendarHeader-root': {
      '.MuiPickersCalendarHeader-label': {
        fontWeight: '700',
        fontSize: isDesktop ? '18px' : '15px',
        display: 'flex',
        justifyContent: 'space-between',
      },
      marginTop: '0',
      padding: '5px 12px 5px 24px',
      background: 'purple',
      color: 'white',
      marginBottom: '0px',
      '.MuiSvgIcon-root': {
        color: 'white',
      },
    },
    '.MuiPickersCalendarHeader-switchViewButton': {
      display: 'none',
    },
    '.Mui-selected': {
      background: 'green',
    },
  },
});
