import { lazy } from 'react';

import { UiRoutes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const BookingPage = lazy(() => import('../screens/Booking'));
const VisitorInfo = lazy(() => import('../screens/VisitorInfo'));
const ParkingDirections = lazy(() => import('../screens/ParkingDirections'));
const MyBookings = lazy(() => import('../screens/MyBooking'));

const routesConfig = (org: string): Array<RoutesConfigType> => [
  {
    path: `${org}${UiRoutes.BOOKING}`,
    component: BookingPage,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.VISITOR_HELP}`,
    component: VisitorInfo,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.PARKING_DIRECTION}`,
    component: ParkingDirections,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.MY_BOOKING}`,
    component: MyBookings,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.BOOKING_DEMO}`,
    component: BookingPage,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.VISITOR_HELP_DEMO}`,
    component: VisitorInfo,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.PARKING_DIRECTION_DEMO}`,
    component: ParkingDirections,
    layout: 'public',
  },
  {
    path: `${org}${UiRoutes.MY_BOOKING_DEMO}`,
    component: MyBookings,
    layout: 'public',
  },
];

export default routesConfig;
