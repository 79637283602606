import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { sendFeedback } from '@src/api/rooms';
import { GlobalOrgData } from '@src/contexts/GlobalUIContext';
import { UiRoutes } from '@src/lib/constants';
import { Header } from '@src/screens/Booking/style';

import FeedbackDialog from '../Modal/FeedbackModal';

export const PageHeader = ({ onClick }: { onClick?: () => void }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { organisation } = useContext(GlobalOrgData);

  const handleNavigation = () => {
    window.location.href = `/${window.location.pathname.split('/')[1]}`;
  };

  const handleMyBooking = () => {
    navigate(`/${organisation?.org_id || 'demo'}${UiRoutes.MY_BOOKING}`);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          background: 'white',
          zIndex: '100',
          padding: '0 10px',
          maxWidth: '1200px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Header style={{ background: 'transparent' }}>
            <img
              src={organisation?.logoUrl || '/images/Reasy_RGB_full_color.jpg'}
              style={{ width: 'auto', height: '40px' }}
              onClick={onClick ? onClick : handleNavigation}
              alt='logo'
            />
          </Header>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={handleMyBooking}
              variant='outlined'
              style={{
                fontWeight: '700',
                color: 'black',
                padding: '6px 8px',
                textTransform: 'none',
                borderRadius: '5px',
                backgroundColor: 'yellow',
                fontSize: '14px',
              }}
            >
              My Bookings
            </Button>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              variant='outlined'
              style={{
                fontWeight: '700',
                color: 'black',
                padding: '6px 8px',
                textTransform: 'none',
                borderRadius: '5px',
                backgroundColor: 'yellow',
                fontSize: '14px',
              }}
            >
              Feedback
            </Button>
          </div>
        </div>
        <FeedbackDialog
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleSubmit={async feedback => {
            try {
              setIsOpen(false);
              await sendFeedback(feedback);
            } catch (err) {
              console.log('err', err);
            }
          }}
        />
      </div>
    </div>
  );
};
