/* eslint-disable complexity */
import { useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Button, Checkbox, TextareaAutosize } from '@mui/material';

import Loader from '@src/components/Loader';
import FormDialog from '@src/components/Modal/FormModal';
import { PageHeader } from '@src/components/PageHeader';
import { servicesOptions } from '@src/lib/constants';
import { getPosition } from '@src/lib/helper';

import { Calendar } from './Calender';
import { ContactsList } from './ContactsList';
import { LocationsChips } from './locationChip';
import { RenderCardValue, RenderChipComponent } from './RenderChipComponent';
import { RoomCard } from './roomCard';
import { Heading, StyledChip, StyledMainBox, StyledMainContainer, StyledSelectedGrid } from './style';
import { SelectedValues } from './type';
import { useHomeLogic } from './useHomeLogic';
dayjs.extend(utc);

const Home: React.FC = () => {
  const {
    containerRef,
    getValue,
    handleBookingSubmit,
    handleSelect,
    isDirtyServices,
    isOpen,
    locations,
    rooms,
    selectedServices,
    selectedToggle,
    selectedValues,
    setIsDirtyServices,
    setIsOpen,
    setSelectedServices,
    setSelectedValues,
    setSelectedToggle,
    room,
    setRoom,
    locationSelectionType,
    setLocationSelectionType,
    errorMessage,
    chipComponentsConfig,
    selectSeprateLocation,
    setSelectSeprateLocation,
    getEmptyKey,
    selectedDatePref,
    loading,
    organisation,
    setSelectedDatePref,
    dateRange,
    setDateRange,
    recurringData,
    setRecurringData,
    bookingDates,
    setBookingDates,
  } = useHomeLogic();

  const servicePositions: Record<string, { gridColumn: string }> = {
    meals: { gridColumn: '1' },
    none: { gridColumn: '2' },
    beverages: { gridColumn: '1' },
    itSupport: { gridColumn: '2' },
  };

  const getPositionService = (id: string) => servicePositions[id] || {};

  const handleMultiLocationSelect = (isAllLocation = false) => {
    const updatedValues: SelectedValues = {
      ...selectedValues,
      floorNumber: '0',
      capacity: 'Any Size',
      mediaSupport: 'audio-video',
      type: 'conference',
    };

    if (isAllLocation) {
      setSelectedServices([
        { name: 'beverages', categories: ['Water'] },
        // { name: 'itSupport', categories: ['BOTH audio-voice conference setup AND video-presentation setup'] },
      ]);
    } else {
      setSelectedServices(undefined);
    }

    if (selectSeprateLocation?.location) {
      setSelectedToggle('floorNumber');
    } else {
      setSelectedToggle(getEmptyKey(updatedValues) || '');
    }

    setSelectedValues(updatedValues);
  };

  const scrollToBottom = useCallback(
    (top = 420) => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    },
    [containerRef]
  );

  const selectedValuesString = Object.values(selectedValues || {})
    .join('')
    .replace(/,/g, '');

  const shouldApplyNonMargin = selectedValues?.type === 'conference' && !!selectedServices?.length;

  const excludedKeys = ['endTime', 'startTime', 'endTimeTemp', 'startTimeTemp'];

  const handleChipSelectedClick = (key: string) => {
    const excludedKeysSelect = ['floorNumber', 'type', 'capacity'];

    if (locationSelectionType && excludedKeysSelect.includes(key)) return;

    setSelectedToggle(key);

    if (key === 'location' && !locationSelectionType) {
      setSelectedValues(prev => ({
        ...prev,
        floorNumber: '',
      }));
    }

    if (key === 'type') {
      setSelectedValues(prev => ({ ...prev, capacity: '' }));
    }

    if (room) {
      if (isOpen) setIsOpen(false);

      setRoom(undefined);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!organisation) {
    return (
      <StyledMainContainer>
        <StyledChip>No Service Found</StyledChip>
      </StyledMainContainer>
    );
  }

  return (
    <StyledMainBox ref={containerRef}>
      <PageHeader />
      <StyledMainContainer>
        {/* slected list  */}
        {selectedValuesString ? (
          <div style={{ marginBottom: '10px' }}>
            <StyledSelectedGrid $isNonMargin={shouldApplyNonMargin}>
              {Object.keys(selectedValues).map(key => {
                const value = getValue(key, selectedValues[key] as string);
                const isExcludedKey = excludedKeys.includes(key);

                if (isExcludedKey || !value) return null;

                return (
                  <StyledChip
                    key={key}
                    $isSelected
                    $isLocation
                    style={getPosition(key)}
                    onClick={() => handleChipSelectedClick(key)}
                  >
                    {key === 'mediaSupport' ? <div>Media</div> : null}
                    {selectedDatePref === 'multipleDays' && ['selectedDate', 'selectedEndDate'].includes(key) ? (
                      <>
                        <div>{value[0]}</div>
                        <div>{value[1]}</div>
                      </>
                    ) : key === 'floorNumber' ? (
                      value === '0' ? (
                        'Any Floor'
                      ) : (
                        `${value} Floor`
                      )
                    ) : (
                      value
                    )}
                  </StyledChip>
                );
              })}
            </StyledSelectedGrid>
          </div>
        ) : null}
        {selectedValues?.type === 'conference' && selectedServices?.length ? (
          <div style={{ marginBottom: '10px' }}>
            <StyledSelectedGrid $isConference>
              {selectedServices?.map(service => {
                const label = servicesOptions?.find(({ id }) => id === service.name)?.name || '';
                return (
                  <StyledChip
                    $isSelected
                    key={label}
                    onClick={() => {
                      setSelectedToggle('services');
                      setIsDirtyServices(!!selectedServices?.length);
                      setRoom(undefined);
                    }}
                    style={{ background: '#047ea3', border: '1px solid #047ea3' }}
                  >
                    {label === 'None' ? ' NO SERVICES' : label}
                  </StyledChip>
                );
              })}
            </StyledSelectedGrid>
          </div>
        ) : null}

        {selectedToggle === 'selectedDate' ? (
          <Calendar
            handleSelect={handleSelect}
            selectedDate={selectedValues.selectedDate}
            selectedDatePref={selectedDatePref}
            setSelectedDatePref={setSelectedDatePref}
            dateRange={dateRange}
            scrollToBottom={scrollToBottom}
            setDateRange={setDateRange}
            recurringData={recurringData}
            setRecurringData={setRecurringData}
            bookingDates={bookingDates}
            setBookingDates={setBookingDates}
          />
        ) : null}
        {selectedToggle === 'location' && locations.length ? (
          <LocationsChips
            handleMultiLocationSelect={handleMultiLocationSelect}
            handleSelect={handleSelect}
            locationSelectionType={locationSelectionType}
            locations={locations}
            selectedValues={selectedValues}
            setLocationSelectionType={setLocationSelectionType}
            setSelectedValues={setSelectedValues}
            selectSeprateLocation={selectSeprateLocation}
            setSelectedToggle={setSelectedToggle}
            setSelectSeprateLocation={setSelectSeprateLocation}
          />
        ) : null}
        {selectedValues?.type === 'conference' && selectedToggle === 'services' ? (
          <div
            style={{
              marginBottom: '8px',
            }}
          >
            <Heading
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none',
                textTransform: 'uppercase',
                marginBottom: '10px',
              }}
            >
              Services
              {/* <Button
                onClick={() => {
                  const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                    return !selectedValues[emptyValueKey];
                  });

                  setSelectedToggle(valueKey || '');
                  !selectedServices?.length && setSelectedServices([{ name: 'none' }]);
                }}
                variant='contained'
                sx={{ padding: 0, mb: 1 }}
                color='success'
              >
                Done
              </Button> */}
            </Heading>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '8px',
              }}
            >
              {servicesOptions?.map(({ id, name, subCheckBox }) => {
                const isSelected = selectedServices?.some(({ name: stateName }) => stateName === id);

                const removeNone = isDirtyServices
                  ? isDirtyServices
                  : id === 'none' &&
                    !selectedServices?.some(({ name: stateName }) => stateName === 'none') &&
                    selectedServices?.length
                  ? false
                  : true;

                const handleChipClick = () => {
                  isDirtyServices && setIsDirtyServices(false);

                  if (isSelected && selectedServices) {
                    setSelectedServices(selectedServices.filter(({ name: stateName }) => stateName !== id));
                  } else {
                    setSelectedServices(prev =>
                      id === 'none'
                        ? [{ name: 'none' }]
                        : [
                            ...(prev || []).filter(({ name: stateName }) => stateName !== 'none'),
                            {
                              name: id,
                              ...(subCheckBox
                                ? {
                                    categories:
                                      id === 'itSupport' ? ['ONLY audio-voice setup for conference call'] : ['Water'],
                                  }
                                : {}),
                            },
                          ]
                    );

                    if (id === 'none') {
                      const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                        return !selectedValues[emptyValueKey];
                      });

                      setSelectedToggle(valueKey || '');
                    }
                  }
                };

                return (
                  <div
                    key={name}
                    style={{
                      ...getPositionService(id),
                      border: '1px solid #05b6d8',
                      borderRadius: '7px',
                      display: 'flex',
                      flexDirection: 'column',
                      height: isSelected ? 'auto' : 'fit-content',
                    }}
                  >
                    <StyledChip
                      key={id}
                      style={{ border: '1px solid #05b6d8' }}
                      $isDisable={!removeNone}
                      $isSelected={
                        isSelected
                          ? subCheckBox
                            ? !!selectedServices?.find(({ name: stateName }) => stateName === id)?.categories?.length
                            : isSelected
                          : isSelected
                      }
                      onClick={() => {
                        removeNone && handleChipClick();
                      }}
                    >
                      {name}
                    </StyledChip>
                    {isSelected && subCheckBox ? (
                      <div
                        style={{
                          padding: '5px 5px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          flex: 1,
                          gap: '5px',
                        }}
                      >
                        {subCheckBox.map(categories => {
                          const selectedCategort =
                            selectedServices?.find(({ name: stateName }) => stateName === id)?.categories || [];

                          return (
                            <div
                              key={categories}
                              style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10 px' }}
                            >
                              <Checkbox
                                sx={{ padding: 0, mr: '5px' }}
                                checked={selectedCategort.includes(categories)}
                                onChange={({ target: { checked } }) => {
                                  if (checked) {
                                    setSelectedServices(prev =>
                                      prev?.map(value =>
                                        value?.name === id
                                          ? {
                                              ...value,
                                              categories:
                                                id === 'itSupport'
                                                  ? [categories]
                                                  : [...(value?.categories || []), categories],
                                            }
                                          : value
                                      )
                                    );
                                  } else {
                                    setSelectedServices(prev =>
                                      prev?.map(value =>
                                        value?.name === id
                                          ? {
                                              ...value,
                                              categories: (value?.categories || []).filter(
                                                valueName => valueName !== categories
                                              ),
                                            }
                                          : value
                                      )
                                    );
                                  }
                                }}
                              />
                              <div
                                style={{
                                  textTransform: id === 'beverages' ? 'uppercase' : 'none',
                                  fontSize: '12px',
                                  marginTop: '4px',
                                }}
                              >
                                {categories}
                              </div>
                            </div>
                          );
                        })}
                        <div>
                          <TextareaAutosize
                            placeholder='Special Instructions'
                            value={
                              selectedServices?.find(({ name: selectedServiceName }) => selectedServiceName === id)
                                ?.specialInstructions || ''
                            }
                            minRows={2}
                            style={{
                              padding: '5px',
                              fontSize: '12px',
                              marginBottom: '5px',
                              border: '1px solid rgb(5, 182, 216)',
                              borderRadius: '5px',
                              resize: 'none',
                              width: '100%',
                              boxSizing: 'border-box',
                            }}
                            onChange={e => {
                              setSelectedServices(prev =>
                                prev?.map(data =>
                                  data?.name === id ? { ...data, specialInstructions: e.target.value } : data
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
              <StyledChip
                style={{
                  gridColumn: '2',
                  color: 'white',
                  background: 'green',
                  borderColor: 'green',
                }}
                onClick={() => {
                  const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                    return !selectedValues[emptyValueKey];
                  });

                  setSelectedToggle(valueKey || '');
                  !selectedServices?.length && setSelectedServices([{ name: 'none' }]);
                }}
                $size='l'
              >
                Next
              </StyledChip>
            </div>
          </div>
        ) : null}

        {chipComponentsConfig.map((config, index) =>
          config.condition ? (
            <RenderChipComponent
              key={index}
              floors={config.floors}
              values={config.values as RenderCardValue[]}
              keyName={config.keyName}
              heading={config.heading}
              columnNumber={config.columnNumber}
              handleSelect={handleSelect}
              selectedValues={selectedValues}
              floorNotFound={config.floorNotFound}
            />
          ) : null
        )}
        {selectedValues.endTime && selectedValues.startTime ? (
          <div style={{ display: 'grid', gridTemplateColumns: `repeat(2, 1fr)`, gap: '10px', marginBottom: '10px' }}>
            {selectedValues.startTime ? (
              <StyledChip
                $isSelected
                style={{ background: 'rgb(5, 62, 86)', height: '50px' }}
                onClick={() => {
                  setSelectedToggle('startTimeTemp');
                }}
              >
                <div>Start Time</div>
                {getValue('startTime', selectedValues.startTime)}
              </StyledChip>
            ) : null}
            {selectedValues.endTime ? (
              <StyledChip
                $isSelected
                style={{ background: 'rgb(5, 62, 86)', height: '50px' }}
                onClick={() => {
                  setSelectedToggle('endTimeTemp');
                }}
              >
                <div>End Time</div>
                {getValue('endTime', selectedValues.endTime)}
              </StyledChip>
            ) : null}
          </div>
        ) : null}
        {rooms?.length &&
        (!locationSelectionType || (locationSelectionType === 'allLocations' && selectSeprateLocation?.location)) ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(2, 1fr)`,
              gap: '10px',
              paddingBottom: '10px',
            }}
          >
            {organisation?.isAddAttendent ? (
              <ContactsList
                endTime={(getValue('endTime', selectedValues.endTime) || '') as string}
                startTime={(getValue('startTime', selectedValues.startTime) || '') as string}
              />
            ) : null}
            {selectedDatePref === 'singleDayMultiBooking' && selectedToggle !== 'selectRoom' ? (
              <div style={{ gridColumn: 'span 2', display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                <Button
                  color='success'
                  variant='contained'
                  disabled={!room?.length}
                  onClick={() => {
                    setSelectedToggle('selectRoom');
                  }}
                >
                  Done
                </Button>
              </div>
            ) : null}
            {rooms.map(roomDetaild => {
              const isRoomSelcted = !!room?.some(({ roomId }) => roomId === roomDetaild._id);
              const showAll = selectedToggle === 'selectRoom';
              return (showAll ? isRoomSelcted : true) ? (
                <RoomCard
                  key={roomDetaild._id}
                  room={roomDetaild}
                  isSelected={isRoomSelcted}
                  onClick={() => {
                    setRoom(prev =>
                      selectedDatePref === 'singleDayMultiBooking'
                        ? [
                            ...(prev || []),
                            {
                              roomNumber: roomDetaild?.roomNumber,
                              endTime: selectedValues.endTime,
                              startTime: selectedValues.startTime,
                              roomId: roomDetaild._id,
                            },
                          ]
                        : [
                            {
                              roomNumber: roomDetaild?.roomNumber,
                              endTime: selectedValues.endTime,
                              startTime: selectedValues.startTime,
                              roomId: roomDetaild._id,
                            },
                          ]
                    );

                    if (selectedDatePref !== 'singleDayMultiBooking') {
                      setSelectedToggle('selectRoom');
                    }
                  }}
                />
              ) : null;
            })}
          </div>
        ) : null}
        {selectedToggle === 'selectRoom' ? (
          <FormDialog
            scrollToBottom={scrollToBottom}
            handleClose={() => {
              const valueKey = Object.keys(selectedValues).find(emptyValueKey => {
                return !selectedValues[emptyValueKey];
              });

              setSelectedToggle(valueKey || '');
            }}
            onSubmit={handleBookingSubmit}
          />
        ) : null}
        {errorMessage && !selectedToggle ? <h3 style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</h3> : null}
      </StyledMainContainer>
    </StyledMainBox>
  );
};

export default Home;
